@import "./light.css";

:root {
    --color-danger: 9, 183, 253;
    --color-gradient: linear-gradient(
        90deg,
        rgba(66, 199, 252, 1) 0%,
        rgba(9, 183, 253, 1) 40%,
        rgba(15, 150, 204, 1) 100%
    );
}

.filter-tag {
    border-color: rgb(var(--color-dimmed));
}
