:root {
    --color-default: 22, 22, 22;
    --color-fill: 255, 255, 255; /* RGB */
    --color-box-shadow: #222;
    --color-base: 255, 255, 255;
    --color-light: 22, 22, 22;
    --color-dimmed: 107, 107, 107;
    --color-sub-base: 205, 205, 205;
    --color-select: 190, 190, 190;
    --color-base-light: 219, 219, 219;
    --color-wrapper: 247, 247, 247;
}

input {
    -webkit-text-fill-color: unset !important;
}
.ril__outer {
    background: transparent;
}
.ril__outer .ril-toolbar__item {
    color: rgba(var(--color-base), 0.2);
}
.ril__outer .ril-toolbar__item .lightboxBtn {
    border-color: rgba(var(--color-base), 0.2);
}
.ril__outer .ril__zoomInButton,
.ril__outer .ril__zoomOutButton,
.ril__outer .ril__closeButton {
    filter: brightness(0.8) hue-rotate(280deg) saturate(5);
    border: 1px solid rgba(var(--color-base), 0.2);
    padding: 19px 0;
}
